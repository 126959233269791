(function ($) {
    var $html = $('html'),
        $changeSize = $('.js-change-size'),
        currentFontSize

    if (sessionStorage.getItem('font-size')) {
        var size = parseInt(sessionStorage.getItem('font-size'))
        currentFontSize = (size >= 14 && size <= 20) ? size : 16
        $html.css('fontSize', parseInt(currentFontSize))
    }
    else {
        currentFontSize = parseInt($html.css('fontSize'))
    }

    $changeSize.on('click', function(e) {
        e.preventDefault();
        var direction = $(this).attr('data-direction')

        if (direction == 'plus') {
            if (currentFontSize < 20) {
                $html.css('fontSize', currentFontSize + 1)
                currentFontSize = currentFontSize + 1
                
                sessionStorage.setItem('font-size', $html.css('fontSize'))
            }
        }
        else {
            if (currentFontSize > 14) {
                $html.css('fontSize', currentFontSize - 1)
                currentFontSize = currentFontSize - 1
                sessionStorage.setItem('font-size', $html.css('fontSize'))
            }
        }
    })

})(jQuery);